$mobile: 900px;
$smallMobile: 700px;

.intro {
    display: flex;
    height: 100vh;
    width: 100vw;

    @media screen and (max-width: $mobile) {
        flex-direction: column-reverse;

        .name {
            line-height: 80%;
            padding-bottom: 20px;
        }

        .left {

            .wrapper {
                margin: 30px;
                padding: 0 !important;
            }
        }

        .scroll-icon {
            opacity: 0;
        }
    }

    @media screen and (max-height: $smallMobile) {
        height: 120vh;
    }

    .left {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .wrapper {
            padding: 50px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            max-width: 540px;

            .intro-text {
                font-size: 30px;
                font-weight: 300;
            }

            .name {
                font-size: 60px;
                line-height: 100%;
                margin-bottom: 20px;
            }

            .title {
                height:50px;
                overflow: hidden;
                margin-bottom: 10px;

                .title-wrapper {
                    height: 100%;
                    animation: move 15s ease-in-out infinite alternate;
                }
                
                // There's 100% a better way to do this 😅
                @keyframes move {
                    0% {
                        transform: translateY(0px);
                    }
                    1% {
                        transform: translateY(0px);
                    }
                    15% {
                        transform: translateY(-50px);
                    }
                    29% {
                        transform: translateY(-100px);
                    }
                    43% {
                        transform: translateY(-150px);
                    }
                    57% {
                        transform: translateY(-200px);
                    }
                    71% {
                        transform: translateY(-250px);
                    }
                    85% {
                        transform: translateY(-300px);
                    }
                    99% {
                        transform: translateY(-350px);
                    }
                    100% {
                        transform: translateY(-350px);
                    }
                }

                .item {
                    height: 50px;
                    font-size: 30px;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                }
            }
        }

        .scroll-icon {
            border: 2px solid black;
            width: 24px;
            height: 44px;
            border-radius: 20px;
            position: absolute;
            transform: translateY(40vh);
            left: 49%;
            display: flex;
            justify-content: center;

            .dot {
                border: 2px solid black;
                background-color: black;
                width: 2px;
                height: 2px;
                border-radius: 20px;
                animation: scroll 3s ease-in-out infinite;
            }

            @keyframes scroll {
                0%   { 
                    transform: translateY(8px);
                    opacity: 0;
                }
                20%  {
                    transform: translateY(8px);
                    opacity: 1;
                }
                100% {
                    transform: translateY(20px);
                    opacity: 0;
                }
            }
        }
    }
    
    .right {
        flex: 1;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img {
            width: 80%;
        }


        .bg {
            position: absolute;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            border-radius:50%;
            height: 35vw;
            width: 35vw;
            max-width: 500px;
            max-height: 500px;

            @media screen and (max-width: $mobile) {
                height: 40vh;
                width: 40vh;
                transform: translateY(20px);
            }

            .poster {
                width: 140%;
                height: auto;
                max-width: 720px;
            }

            .video {
                width: 140%;
                max-width: 600px;
                max-height: 600px;
                // transform: translateX(10px);
            }
        }
    }
}