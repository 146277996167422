$footer-color: #80808020;
$theme-color: #4B9CF1;

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.footer-wrapper {
    @extend .centered;

    .message-1 {
        font-size: 20px;
    }

    .message-2 {
        font-size: 12px;
    }

    .footer {
        @extend .centered;
        padding: 20px 0 30px 0;
        opacity: 1;
        font-size: 12px;
        width: 100%;
        background-color: $footer-color;

        div {

            display: flex;

            i {
                color: $theme-color;
                padding: 4px;
            }

            .footer-svg {
                fill: $theme-color;
                width: 20px;
                height: 20px;
            }

            .icon-wrapper {
                margin: 2px 4px;
                transition-duration: 0.2s;
                cursor: pointer;

                &:hover {
                    transform: scale(1.2);
                }

                &:active {
                    transform: scale(0.9);
                }
            }

        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .spin {
        animation: spin 4s linear infinite;
    }
}
