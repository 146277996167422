$mobile: 900px;

.dark-mode-toggle-container {

    opacity: 0.6;
    height: 40px;
    width: 40px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle-outline {
        transition-duration: 1s;
        height: 30px;
        width: 30px;
        border: 2px solid black;
        border-radius: 50%;
    }

    .dotted {
        border: 2px dotted black;
        transform: rotate(90deg);
    }

    .solid {
        border-style: solid;
        transform: rotate(0deg);
    }

    .circle {
        transition-duration: 0.2s;
        width: 22px;
        height: 22px;
        border-radius: 50%;
    }

    .moon {
        transform: translate(12px, -6px);
        box-shadow: -8px 10px 0 0 black;
    }

    .sun {
        // transform: translate(32px, -10px) scale(1.4);
        transform: translate(24px, -6px);
        box-shadow: -20px 10px 0 0 black;
    }

    // @media screen and (max-width: $mobile) {

    // }
}