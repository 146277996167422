.magic-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-top: 14px;

    @media screen and (max-width: 500px) {
        width: 80%;
    }

    .magic-picture-img {
        width: 90%;
    }
}