$Blue: #509CEF;
$White: #ffff;
$darkModeBlack: #111111;
$mobile: 650px;

.flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-wrapper {
    @extend .flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;

    .wrapper-style {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .card-wrap-style {
            position: relative;
            width: 80vw;
            max-width: 900px;
            max-height: 600px;
            height: 60vh;
            margin: 0;
            padding: 0;

            @media screen and (max-width: $mobile) {
                width: 84vw;
                height: 60vh;
                min-height: 520px;
            }

            .card-style {
                border: 4px solid $Blue;
                box-shadow: 8px 8px 14px 0px rgba(0, 0, 0, 0.6);
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 10px;
                list-style: none;
                overflow: hidden;
                @extend .flex;
                flex-direction: column;

                .card-inner {
                    height: 100%;
                    padding: 10px;
                    @extend .flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    cursor: grab;
                    
                    &:active {
                        cursor: grabbing;
                    }

                    @media screen and (min-width: $mobile) {
                        flex-direction: row;

                        h1, .text {
                            width: 50%;
                            margin: 10px;
                        }

                        .magic-picture {
                            max-height: 50%;
                        }
                    }

                    .dot-wrapper {
                        display: flex;
                        padding: 5px 0;
                        z-index: 2;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        border-bottom: 4px solid $Blue;
                
                        .dot {
                            border-radius: 10px;
                            height: 10px;
                            width: 10px;
                            margin-left: 5px;
                        }
                    }

                    h1 {
                        margin: 10px 0;

                        @media screen and (max-width: $mobile) {
                            font-size: 6vw;
                        }
                    }

                    .description {
                        font-size: 14px;
                    }

                    .links-wrapper {
                        margin-top: 8px;
                        display: flex;
                        flex-direction: column;

                        @media screen and (max-width: $mobile) {
                            flex-direction: row !important;
                        }
                    }

                    .date-tags-wrapper {
                        @extend .flex;
                        flex-direction: column-reverse;
                        align-items: baseline;
                        position: absolute;
                        bottom: 10px;
                        left: 6px;
                        
                        .date {
                            font-size: 10px;
                            opacity: 0.6;
                            margin: 4px 4px -2px 4px;
                            transition-duration: 0.2s;

                            &:hover {
                                opacity: 1;
                            }
                        }

                        // Hiding these for now
                        .tags-wrapper {
                            opacity: 0;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: flex-start;

                            .tag {
                                font-size: 10px;
                                margin: 2px;
                                opacity: 0.6;
                                border: 1px solid $darkModeBlack;
                                border-radius: 10px;
                                padding: 2px 5px;
                                transition-duration: 0.2s;
                                
                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }

                    .projct-buttons-wrapper {
                        @extend .flex;
                        position: absolute;
                        bottom: 3px;
                        right: 4px;

                        .project-nav-button {
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            background: none;
                            border: none;
                            opacity: 0.6;
                            transition-duration: 0.2s;
                            transform: scale(1);
                            cursor: pointer;
                    
                            :focus {
                                -webkit-tap-highlight-color: transparent;
                                outline: none;
                                -ms-touch-action: manipulation;
                                touch-action: manipulation;
                            }
                    
                            &:hover {
                                opacity: 1;
                                transform: scale(1.1);
                            }
                    
                            &:active {
                                opacity: 0.8;
                                transform: scale(0.8);
                            }
                        }
                    }

                }

            }
        }

    }

    .bottom-info {
        width: 100%;
        @extend .flex;
        flex-direction: column;

        .project-number {
            padding: 4px 10px;
            border-radius: 10px;
            z-index: 10;
            margin-top: 10vh;
            backdrop-filter: blur(4px);

            span {
                opacity: 0.5;
            }
        }

        .bottom-message {
            margin: 20px;
            text-align: center;
        }
        
    }

}