$mobile: 650px;

.socials {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    a {
        margin: 0 2px 0 0;
    }

    img {
        height: 40px;
        width: 40px;
        cursor: pointer;
        font-size: 40px;
        margin: 6px;
        color: black;
    }

    @media screen and (max-width: $mobile) {

        img {
            height: 40px;
            width: 40px;
        }
    }
}