.video-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition-duration: 1s;

    .video {
        transition-duration: 1s;
        position: absolute;
        z-index: 2;
        filter: saturate(1.2);
    }

    .poster {
        transition-duration: 1s;
        filter: saturate(1.2);
        aspect-ratio: 1 / 1;
    }

    .hidden {
        opacity: 0;
    }

    .visible {
        opacity: 1;
    }
}