$mobile: 900px;
$small-mobile: 500px;
$theme-color: #4B9CF1;
// old theme colour ? #4B8FDA

.visible {
    opacity: 1;
    transition-duration: 1s;
    transition-property: opacity;
}

.hidden {
    opacity: 0;
    transition-duration: 1s;
}

.about {
    height: 100vh;
    overflow: none;
    display: flex;
    align-items: center;
    overflow-x: hidden;

    @media screen and (max-width: $mobile) {
        flex-direction: column;
        height: auto !important;

        .right {
            margin: 30px;
            max-width: 80%;
            padding: 0 !important;
        }
    }

    .left {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        width: 100%;

        .window {
            transition-duration: 0.2s;
            border-radius: 10px;
            width: 80%;
            height: 60vh;
            position: relative;
            overflow: hidden;

            @media screen and (max-width: $small-mobile) {

                height: 90vw;
                width: 90vw;

                .fill {
                    left: -34%;
                    top: -26% !important;
                    transform: scale(0.6);
                    width: 168% !important;
                    height: 158% !important;
                }
            }

            .fill {
                height: 96%;
                width: 100%;
                border-radius: 0 0 5px 5px;
                top: 24px;
                position: absolute;
                z-index: 1;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                .laptop-image {
                    position: absolute;
                    z-index: 4;
                    transform: translateY(170px);
                    width: 380px;
                }

                .video {
                    z-index: 2;
                    width: 840px;
                    transform: translateY(50px);
                }

                .poster {
                    width: 840px;
                    transform: translateY(50px);
                }

                .logos {
                    z-index: 3;
                    height: 500px;
                    width: 500px;
                    position: absolute;
                    animation: rotating 40s linear infinite;

                    .react {
                        animation: rotating 10s linear infinite;
                    }

                    img {
                        position: absolute;
                        width: 100px;
                        height: 100px;
                        animation: rotating 40s linear infinite reverse;
                    }

                    :nth-child(1) {
                        top: 0;
                        left: 200px;
                    }

                    :nth-child(2) {
                        top: 200px;
                        right: 0;
                    }

                    :nth-child(3) {
                        bottom: 0;
                        right: 200px;
                    }

                    :nth-child(4) {
                        bottom: 200px;
                        left: 0;
                    }

                    :nth-child(5) {
                        top: 60px;
                        left: 50px;
                    }

                    :nth-child(6) {
                        top: 60px;
                        right: 50px;
                    }

                    :nth-child(7) {
                        bottom: 60px;
                        right: 50px;
                    }

                    :nth-child(8) {
                        bottom: 60px;
                        left: 50px;
                    }
                }

                @keyframes rotating {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }

            .dot-wrapper {
                position: relative;
                display: flex;
                padding: 5px 0;
                z-index: 2;

                .dot {
                    border-radius: 10px;
                    height: 10px;
                    width: 10px;
                    margin-left: 5px;
                }
            }

            .window-bg {
                background-color: $theme-color;
                position: absolute;
                width: calc(100% + 2px);
                height: 100%;
            }
        }

        .card {
            width: 60%;
            height: 70vh;
            border-radius: 30px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

        }

        .bg {
            position: absolute;
            top: 80px;
            left: 80px;
            background-color: $theme-color;
            z-index: 1;
        }
    }

    .right {
        flex: 1;
        padding-right: 60px;

        .sub {
            margin: 10px 0;
        }

        .award {

            margin-top: 50px;
            display: flex;
            align-items: center;

            h4 {
                opacity: 0.6;
            }

            img {
                width: 100px;
                height: 100px;
            }
        }

       
    }
}