$mobile: 900px;

/* Article - https://bitsofco.de/github-contribution-graph-css-grid/ */

/* Grid-related CSS */

$Blue: #509CEF; 
$gray: #6c6c6c22;

:root {
    --square-size: 40px;
    --square-gap: 5px;
    --week-width: calc(var(--square-size) + var(--square-gap));
  }
  
.months { grid-area: months; }
.days { grid-area: days; }
.squares { grid-area: squares; }

.graph {
    display: inline-grid;
    grid-template-areas: "empty months"
                            "days squares";
    grid-template-columns: auto 1fr;
}

.months {
    display: grid;
    grid-template-columns: calc(var(--week-width) * 4) /* Jan */
                        calc(var(--week-width) * 4) /* Feb */
                        calc(var(--week-width) * 4) /* Mar */
                        calc(var(--week-width) * 5) /* Apr */
                        calc(var(--week-width) * 4) /* May */
                        calc(var(--week-width) * 4) /* Jun */
                        calc(var(--week-width) * 5) /* Jul */
                        calc(var(--week-width) * 4) /* Aug */
                        calc(var(--week-width) * 4) /* Sep */
                        calc(var(--week-width) * 5) /* Oct */
                        calc(var(--week-width) * 4) /* Nov */
                        calc(var(--week-width) * 5) /* Dec */;
}

.days,
.squares {
    list-style: none;
    display: grid;
    grid-gap: var(--square-gap);
    grid-template-rows: repeat(7, var(--square-size));
}

.squares {
    margin: 0;
    padding: 0;
    grid-auto-flow: column;
    grid-auto-columns: var(--square-size);
}

.days li:nth-child(odd) {
    visibility: hidden;
}

.squares {

    li {

        &:hover {
            transform: scale(0.6);
        }
    }

    .level-hidden {
        opacity: 0 !important;
    }

    .level-0 {
        background-color: $gray;
        opacity: 1;
        animation-fill-mode: both;
        animation: fadeIn 1s both;
        transition-duration: 0.4s;
    }
   

    .level-1 {
        background-color: $Blue;
        opacity: 0.4;
        animation-fill-mode: both;
        animation: fadeIn 1s both;
        transition-duration: 0.4s;
    }
    
    .level-2 {
        background-color: $Blue;
        opacity: 0.6;
        animation-fill-mode: both;
        animation: fadeIn 1s both;
        transition-duration: 0.4s;
    }
    
    .level-3 {
        background-color: $Blue;
        opacity: 0.8;
        animation-fill-mode: both;
        animation: fadeIn 1s both;
        transition-duration: 0.4s;
    }

    .level-4 {
        background-color: $Blue;
        opacity: 1;
        animation-fill-mode: both;
        animation: fadeIn 1s both;
        transition-duration: 0.4s;
    }
}

@keyframes fadeIn {
    0% {
        transform: scale(0.6);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: auto;
    }
}

.hidden-square {
    background-color: $gray;
    opacity: 1;
    animation-fill-mode: both;
    animation: fadeIn 1s both;
    transition-duration: 0.4s;
}

// @media screen and (max-width: $mobile) {

// }
