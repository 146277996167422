$Blue: #4B9CF1; 
// old blue #509CEF
$Green: rgb(69, 233, 74);

$darkModeBlack: #111111;
$white: #ffff;

html {
    scroll-behavior: smooth;
    overflow: auto;
}

body {
    overscroll-behavior-y: none;
    overflow-x: hidden;
}

.main-wrapper {
    transition-duration: 0.4s;
}

.dark-mode-background {
    transition-duration: 0.4s;
    background-color: $white;
}

.dark-mode {
    transition-duration: 0.4s;
    color: $white;    
    background-color: $darkModeBlack;

    .dark-mode-background {
        transition-duration: 0.4s;
        background-color: $darkModeBlack !important;
    }
    
    .invert-for-dark-mode {
        filter: invert(1);
    }

}

.blue-theme {
    .theme-colour-text {
        color: $Blue;
    }
    
    .theme-colour-background {
        background-color: $Blue;
    }
    
    .theme-colour-border {
        border: 4px solid $Blue;
    }
}

.green-theme {
    .theme-colour-text {
        color: $Green;
    }
    
    .theme-colour-background {
        background-color: $Green;
    }
    
    .theme-colour-border {
        border: 4px solid $Green;
    }
}

::selection {
    background: $Blue; /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: $Blue; /* Gecko Browsers */
}

.hover {
    transition-duration: 0.2s;
    
    &:hover {
        transform: scale(1.1);
    }
}

.delay {
    transition-delay: 0.5s;
    transition-property: opacity;
}

.delay-1 {
    transition-delay: 1s;
    transition-property: opacity;
}

.delay-2 {
    transition-delay: 1.5s;
    transition-property: opacity;
}

.delay-3 {
    transition-delay: 2.0s;
    transition-property: opacity;
}

.delay-4 {
    transition-delay: 2.5s;
    transition-property: opacity;
}