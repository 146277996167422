.link-icon { 

    display: flex; 
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: black;
    margin: 2px 0;
    transition-duration: 1s;

    &:hover {
        .link-text {
            background: black;
            color: white;
        }
    }

    .link-img {
        width: 40px;
        height: 40px;
        z-index: 2;
    }

    .link-text {
        transition-duration: 0.2s;
        font-size: 18px;
        margin-left: -8px;
        border: 2px solid black;
        padding: 1px 6px 2px 6px;
        border-radius: 0 6px 6px 0;
        background: white;
        color: black;

        @media screen and (max-width: 650px) {
            opacity: 0;
            display: none;
        }
    }
}