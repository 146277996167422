$mobile: 900px;

.projects {
    display: flex;
    width: 100vw;
    flex-direction: column;

    .top {
        display: flex;
        align-items: center;
        justify-content: center;

        h1 {
            z-index: 3;
            font-size: 50px;
            position: absolute;
        }
    }

    .bottom {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .empty-grid-div {
        height: 670px;
    }

    .empty-project-div {
        height: 100vh;
    }
    
}